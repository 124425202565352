<script>
	let state = 1
	let multiplier = 1.845
	let profiles = [
		{
			id: 1,
			title: "Kesto-Lukko",
			prices: [ 16.50, 14, 13 ],
			dimensions: { min: 800, max: 18000, width: 475 },
			snow: "lukko",
			img: "kesto-lukko-kone",
			coating: [
				{
					thickness: 0.6,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2.5
				}, {
					thickness: 0.5,
					coating: "Crown Matta BT",
					info: "Esteettinen takuu 15 vuotta<br>Tekninen takuu 40 vuotta",
					color: 1,
					price: 0
				}
			],
			avail: 0.475
		}, {
			id: 2,
			title: "Kesto-Tiilikuvio L",
			prices: [ 11, 10, 9.80 ],
			dimensions: { min: 800, max: 8600, width: 1115 },
			snow: "tiili",
			img: "kestotiili",
			coating: [
				{
					thickness: 0.5,
					coating: "Crown Matta BT",
					info: "Esteettinen takuu 15 vuotta<br>Tekninen takuu 40 vuotta",
					color: 1,
					price: 0
				}, {
					thickness: 0.5,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 1.5
				}, {
					thickness: 0.6,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2.5
				}
			],
			avail: 1.115
		}, {
			id: 3,
			title: "Kesto-Tiilikuvio XL",
			prices: [ 11.50, 10.50, 10.30 ],
			dimensions: { min: 800, max: 8600, width: 1115 },
			snow: "tiili",
			img: "kestotiili",
			coating: [
				{
					thickness: 0.5,
					coating: "Crown Matta BT",
					info: "Esteettinen takuu 15 vuotta<br>Tekninen takuu 40 vuotta",
					color: 1,
					price: 0
				}, {
					thickness: 0.5,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2
				}, {
					thickness: 0.6,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2.5
				}
			],
			avail: 1.115
		}, {
			id: 4,
			title: "Kesto-20 Suoraprofiili",
			prices: [ 11, 10, 9.80 ],
			dimensions: { min: 800, max: 18000, width: 475 },
			snow: "suora",
			img: "kesto20",
			coating: [
				{
					thickness: 0.45,
					coating: "Polyester",
					info: "Esteettinen takuu 10 vuotta<br>Tekninen takuu 30 vuotta",
					color: 2,
					price: 1.5
				}, {
					thickness: 0.5,
					coating: "PUR",
					info: "Esteettinen takuu 15 vuotta<br>Tekninen takuu 40 vuotta",
					color: 2,
					price: 1.5
				}, {
					thickness: 0.5,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2
				}, {
					thickness: 0.6,
					coating: "Pural Matta BT",
					info: "Esteettinen takuu 25 vuotta<br>Tekninen takuu 50 vuotta",
					color: 1,
					price: 2.5
				}
			],
			avail: 1.1
		}
	]
	let colors = [
		[
			[ "RR11", "39493c" ],
			[ "RR23", "494a4f" ],
			[ "RR29", "822c1d" ],
			[ "RR32", "41342b" ],
			[ "RR33", "000" ],
			[ "RR750", "984729" ]
		],
		[
			[ "RR11", "39493c" ],
			[ "RR20", "fff" ],
			[ "RR21", "fff" ],
			[ "RR22", "fff" ],
			[ "RR23", "494a4f" ],
			[ "RR29", "822c1d" ],
			[ "RR32", "41342b" ],
			[ "RR33", "000" ],
			[ "RR750", "984729" ]
		]
	]
	let lists = [
		{ id: 1, title: "Harjalista kantikas (2.0m)", price: 11.00, pcs: "kpl", order: 0 },
		{ id: 2, title: "Harjalista pyöreä (2.0m)", price: 15.40, pcs: "kpl", order: 0 },
		{ id: 3, title: "Päätylista (2.0m)", price: 9.00, pcs: "kpl", order: 0 },
		{ id: 4, title: "Räystäslista (2.0m)", price: 9.00, pcs: "kpl", order: 0 },
		{ id: 5, title: "Taitelista (2.0m)", price: 13.40, pcs: "kpl", order: 0 },
		{ id: 6, title: "Pulpettikaton yläräystäslista (2.0m)", price: 13.80, pcs: "kpl", order: 0 },
		{ id: 7, title: "Seinällenostolista (2.0m)", price: 16.20, pcs: "kpl", order: 0 },
		{ id: 8, title: "Sisäjiirilista 300x300 (2.0m)", price: 18.20, pcs: "kpl", order: 0 },
		{ id: 9, title: "Sisäjiirilista 600x600 (2.0m)", price: 27.40, pcs: "kpl", order: 0 },
		{ id: 10, title: "Harjatiivistelista (0.475m)", price: 1.99, pcs: "kpl", order: 0 }
	]
	let products = [
		{
			id: 1,
			product: "Kateruuvi, porakärki - 28 x 4.8",
			amount: "250 kpl / ltk",
			price: 13.50,
			pcs: "ltk",
			order: 0
		}, {
			id: 2,
			product: "Lukkosauma, asennusruuvi - 25 x 5",
			amount: "250 kpl / ltk",
			price: 19.00,
			pcs: "ltk",
			extra: "lukko",
			order: 0
		}, {
			id: 3,
			product: "Äänitiivistenauha",
			price: 20.00,
			amount: "50 m / rulla",
			pcs: "rulla",
			order: 0
		}, {
			id: 4,
			product: "Harjatiiviste",
			price: 2.15,
			pcs: "metri",
			order: 0
		}, {
			id: 5,
			product: "Harjalistan pääty",
			price: 3.40,
			pcs: "kpl",
			order: 0
		}, {
			id: 6,
			product: "Aumakaton päätykappale",
			price: 12.75,
			pcs: "kpl",
			order: 0
		}, {
			id: 7,
			product: "Aumakaton Y-kappale",
			price: 21.50,
			pcs: "kpl",
			order: 0
		}
	]
	let lumieste = {
		lukko: [
			{
				id: 1,
				product: "Lumieste pyöreä lukkosauma",
				price: 72.00,
				pcs: "kpl",
				order: 0
			}, {
				id: 2,
				product: "Lumieste ovaali lukkosauma",
				price: 85.00,
				pcs: "kpl",
				order: 0
			}
		],
		tiili: [
			{
				id: 1,
				product: "Lumieste pyöreä tiilikuvio",
				price: 65.00,
				pcs: "kpl",
				order: 0
			}, {
				id: 2,
				product: "Lumieste ovaali tiilikuvio",
				price: 72.00,
				pcs: "kpl",
				order: 0
			}
		],
		suora: [
			{
				id: 1,
				product: "Lumieste pyöreä suoraprofiili",
				price: 65.00,
				pcs: "kpl",
				order: 0
			}, {
				id: 2,
				product: "Lumieste ovaali suoraprofiili",
				price: 68.00,
				pcs: "kpl",
				order: 0
			}
		]
	}
	let bridges = {
		lukko: [
			{
				id: 1,
				product: "Kattosiltasarja, 3m, lukkosauma",
				price: 106.00,
				pcs: "sarja",
				order: 0
			}, {
				id: 2,
				product: "Kattosiltasarja, 1m, lukkosauma",
				price: 69.00,
				pcs: "sarja",
				order: 0
			}
		],
		tiili: [
			{
				id: 1,
				product: "Kattosiltasarja, 3m, tiilikuvio",
				price: 106.00,
				pcs: "sarja",
				order: 0
			}, {
				id: 2,
				product: "Kattosiltasarja, 1m, tiilikuvio",
				price: 69.00,
				pcs: "sarja",
				order: 0
			}
		],
		suora: [
			{
				id: 1,
				product: "Kattosiltasarja, 3m, suoraprofiili",
				price: 106.00,
				pcs: "sarja",
				order: 0
			}, {
				id: 2,
				product: "Kattosiltasarja, 1m, suoraprofiili",
				price: 69.00,
				pcs: "metri",
				order: 0
			}
		]
	}
	let ladders = [
		{
			id: 1,
			product: "Seinätikas, 2.4m + asennussarja",
			price: 149.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 2,
			product: "Seinätikas, 3.0m + asennussarja",
			price: 158.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 3,
			product: "Seinätikas, 3.6m + asennussarja",
			price: 165.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 4,
			product: "Seinätikas, 4.2m + asennussarja",
			price: 175.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 5,
			product: "Seinätikas, 4.8m + asennussarja",
			price: 195.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 6,
			product: "Seinätikas, 5.4m + asennussarja",
			price: 204.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 7,
			product: "Seinätikas, 6.0m + asennussarja",
			price: 220.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 6,
			product: "Lapetikas 2.4m + asennussarja, lukkosauma",
			price: 77.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 7,
			product: "Lapetikas 3.0m + asennussarja, lukkosauma",
			price: 90.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 8,
			product: "Lapetikas 3.6m + asennussarja, lukkosauma",
			price: 98.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 9,
			product: "Lapetikas 4.2m + asennussarja, lukkosauma",
			price: 110.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 10,
			product: "Lapetikas 4.8m + asennussarja, lukkosauma",
			price: 145.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 11,
			product: "Lapetikas 6.0m + asennussarja, lukkosauma",
			price: 175.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 12,
			product: "Lapetikas 6.6m + asennussarja, lukkosauma",
			price: 183.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 13,
			product: "Lapetikas 7.2m + asennussarja, lukkosauma",
			price: 187.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 14,
			product: "Lapetikas 2.4m + asennussarja, tiili / suora",
			price: 77.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 15,
			product: "Lapetikas 3.0m + asennussarja, tiili / suora",
			price: 90.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 16,
			product: "Lapetikas 3.6m + asennussarja, tiili / suora",
			price: 98.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 17,
			product: "Lapetikas 4.2m + asennussarja, tiili / suora",
			price: 110.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 18,
			product: "Lapetikas 4.8m + asennussarja, tiili / suora",
			price: 145.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 19,
			product: "Lapetikas 6.0m + asennussarja, tiili / suora",
			price: 175.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 20,
			product: "Lapetikas 6.6m + asennussarja, tiili / suora",
			price: 183.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 21,
			product: "Lapetikas 7.2m + asennussarja, tiili / suora",
			price: 187.00,
			pcs: "kpl",
			order: 0
		}
	]
	let rainwater = [
		{
			id: 1,
			product: "P125 puolipyöreä kouru 3.0m",
			price: 12.00,
			pcs: "kpl"
		}, {
			id: 2,
			product: "P125 puolipyöreä kouru 5.0m",
			price: 20.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 3,
			product: "P125 kourun ulkopuolinen kiinnike",
			price: 2.80,
			pcs: "kpl",
			order: 0
		}, {
			id: 4,
			product: "Syöksysarja pyöreä 90mm (3.5m)",
			price: 41.00,
			pcs: "kpl",
			order: 0
		}, {
			id: 5,
			product: "Kourun säätökiila",
			price: 0.50,
			pcs: "kpl",
			order: 0
		}, {
			id: 6,
			product: "Kourun päätykappale",
			price: 3.05,
			pcs: "kpl",
			order: 0
		}, {
			id: 7,
			product: "Kourun liitoskappale",
			price: 5.90,
			pcs: "kpl",
			order: 0
		}, {
			id: 8,
			product: "Syöksyn lisäkiinnike 90mm",
			price: 2.10,
			pcs: "kpl",
			order: 0
		}, {
			id: 9,
			product: "Syöksyn jatkoputki 90mm/2.3m",
			price: 18.50,
			pcs: "kpl",
			order: 0
		}
	]
	let choices = { active: {}, profile: { dimensions: {} } }
	let error
	let id = 0
	let active_color
	function profileClick() {
		if (state > 1){
			state = 1
			choices.profile
		}
		choices.profile.title = profiles[choices.profile.id].title
		choices.profile.snow = profiles[choices.profile.id].snow
		choices.profile.dimensions = profiles[choices.profile.id].dimensions
		state = 2
	}
	function coatingClick(id){
		if (state > 2){
			state = 2
			choices.profile.coating
		}
		choices.profile.coating = profiles[choices.profile.id].coating[id]
		active_color = choices.profile.coating.color - 1
		extra_price = choices.profile.coating.price
		state = 3
	}
	function colorClick(i){
		if (state > 3){
			state = 3
			choices.profile.color
		}
		choices.profile.color = String(i)
		state = 4
	}
	$: dimensions = [{ id: 1, length: choices.profile.dimensions.min, amount: 1 }]
	$: if(choices.length) dimensions = choices.length
	$: rowNum = dimensions.length
	$: extra_price = 0
	function newFields() {
		dimensions[rowNum] = { id: rowNum + 1, length: choices.profile.dimensions.min, amount: 1 }
	}
	function delFields() {
		dimensions = dimensions.splice(0, rowNum - 1)
	}
	let json
	function finalSubmit(event) {

		var item = event.target
		rowNum = parseInt(document.getElementById("rowNum").value)
		choices.dimensions = []
		choices.extra = []
		choices.total = {
			products: 0,
			shipping: 0,
			vat: 0,
			order: 0
		}

		if ( item.l1.value > 0  && item.la1.value > 0) {

			choices.count = { meters: 0, squares: 0 }
			if (error) { error = "" }

			for( var i = 1; i <= rowNum; i++ ) {
				var x = 0
				var val1 = parseInt(item['l'+ i].value)
				var val2 = parseInt(item['la'+ i].value)
				if (val1 > 0 && val2 > 0){
					var norm = val1
					val1 = val1 / 1000
					var avail = parseFloat(profiles[choices.profile.id].avail)
					var val3 = val1 * val2 * avail
					if (val3 > 100 && val3 < 200) x = 1
					else x = 2
					var price = val3 * (profiles[choices.profile.id].prices[x] + extra_price) * multiplier
					choices.total.products += price
					choices.dimensions.push({
						id: i,
						length: val1.toFixed(2),
						amount: val2,
						square: val3.toFixed(2),
						price: price.toFixed(2),
						avail: avail,
						norm: norm
					});
					let check = i - 1
					dimensions[check].length = norm
					dimensions[check].amount = val2
					choices.count.squares += val3
				}
			}

			choices.length = dimensions

			for( var i = 1; i <= lists.length; i++ ) {
				if (typeof item['list-'+ i] !== "undefined") {
					val1 = parseInt(item['list-'+ i].value)
					if (val1 > 0) {
						var id = i - 1
						var price = lists[id].price * val1 * multiplier
						choices.total.products += price
						choices.extra.push({
							title: lists[id].title,
							price: price.toFixed(2),
							amount: val1
						})
					}
				}
			}

			if (choices.active.products) {
				for( var i = 1; i <= products.length; i++ ) {
					if (typeof item['product-'+ i] !== "undefined"){
						var val1 = parseInt(item['product-'+ i].value)
						if (val1 > 0) {
							var id = parseInt(i-1)
							var price = products[id].price * val1 * multiplier
							choices.total.products += price
							choices.extra.push({
								title: products[id].product,
								amount: val1,
								price: price.toFixed(2)
							})
						}
					}
				}
			}

			if (choices.active.snow) {
				for( var i = 1; i <= lumieste[choices.profile.snow].length; i++ ) {
					if (typeof item['snow-'+ i] !== "undefined") {
						val1 = parseInt(item['snow-'+ i].value)
						var id = parseInt(i-1)
						if (val1 > 0) {
							var id = parseInt(i-1)
							var price = lumieste[choices.profile.snow][id].price * val1 * multiplier
							choices.total.products += price
							choices.extra.push({
								title: lumieste[choices.profile.snow][id].product,
								amount: val1,
								price: price.toFixed(2)
							})
						}
					}
				}
			}

			if (choices.active.bridges) {
				for( var i = 1; i <= bridges[choices.profile.snow].length; i++ ) {
					if (typeof item['bridge-'+ i] !== "undefined") {
						val1 = parseInt(item['bridge-'+ i].value)
						if (val1 > 0) {
							var id = parseInt(i-1)
							var price = bridges[choices.profile.snow][id].price * val1 * multiplier
							choices.total.products += price
							choices.extra.push({
								title: bridges[choices.profile.snow][id].product,
								amount: val1,
								price: price.toFixed(2)
							})
						}
					}
				}
			}

			if (choices.active.ladders) {
				for( var i = 1; i <= ladders.length; i++ ) {
					if (typeof item['ladder-'+ i] !== "undefined") {
						val1 = parseInt(item['ladder-'+ i].value)
						if (val1 > 0) {
							var id = parseInt(i-1)
							var price = ladders[id].price * val1 * multiplier
							choices.total.products += price
							choices.extra.push({
								title: ladders[id].product,
								amount: val1,
								price: price.toFixed(2)
							})
						}
					}
				}
			}

			if (choices.active.rain) {
				for( var i = 1; i <= rainwater.length; i++ ) {
					if (typeof item['rain-'+ i] !== "undefined") {
						val1 = parseInt(item['rain-'+ i].value)
						if (val1 > 0) {
							var id = parseInt(i-1)
							var price = rainwater[id].price * val1 * multiplier
							choices.total.products += price
							choices.extra.push({
								title: rainwater[id].product,
								amount: val1,
								price: price.toFixed(2)
							})
						}
					}
				}
			}

			choices.total.delivery = 150

			if (choices.count.meters > 8.5) choices.total.delivery += 200
			if (choices.count.squares < 50) choices.total.delivery += 30

			choices.total.order = choices.total.products + choices.total.delivery
			choices.total.products = choices.total.products.toFixed(2)
			choices.total.vat = 24 / 100 * choices.total.order
			choices.total.vat = choices.total.vat.toFixed(2)
			choices.total.order = choices.total.order.toFixed(2)

			json = btoa(JSON.stringify(choices))

		} else {
			error = "Täytä kaikki pakolliset kentät."
		}

		state = 5

	}
	async function sendSubmit(event) {

		var choice = btoa(JSON.stringify(choices))

		const formData = new FormData()
		formData.append('post_name', post_name.value)
		formData.append('post_email', post_email.value)
		formData.append('post_phone', post_phone.value)
		formData.append('post_profile', choice)
		formData.append('type', 'newform')
		const res = await fetch("/form.php", {
			method: 'POST',
			body: formData,
			cors: true
		});
		if (res.ok === true) {
			state = 6
		}
	}
</script>

<main>
	<div class="container">
		<div id="logo">
			<div class="inl"><img src="/favicon.png" alt="Kourutuote"></div>
			<h1 class="inl">Katto<span class="blue">laskuri</span></h1>
		</div>
		<div id="content">
			{#if state < 5}
				<section id="profiili">
					<h2>Valitse profiili</h2>
					<div class="row">
						{#each profiles as { id, title, img }, i}
							<div class="profile col-6 r7 mb-3">
								<button class="h-100 w-100" name="profile" value={id} class:active="{choices.profile.id === i}" on:click="{() => choices.profile.id = i}" on:click={() => profileClick(document)}>
									<div class="row h-100 bg-primary">
										<div class="col-4 img bg-white">
											<div class="cell">
												<picture>
													<source srcset={"/images/"+ img +".webp"} media="image/webp">
													<source srcset={"/images/"+ img +".jpg"} media="image/jpeg">
													<img src={"/images/"+ img +".jpg"} alt={title}>
												</picture>
											</div>
										</div>
										<div class="col">
											<div class="cell">{title}</div>
										</div>
									</div>
								</button>
							</div>
						{/each}
					</div>
				</section>
				{#if state > 1}
					<section id="pinnoite">
						<h2>Valitse pinnoite ja paksuus</h2>
						<div class="row">
							{#each profiles[choices.profile.id].coating as { thickness, coating, info }, i}
								<div class="col-4 r7 mb-4"><button class="big bg-primary" name="coating" value={i + 1} class:active="{choices.active.coating === i}" on:click="{() => choices.active.coating = i}" on:click={() => coatingClick(i)}>{thickness +' '+ coating}<br><small>{@html info}</small></button></div>
							{/each}
						</div>
					</section>
					{#if state > 2}
						<section id="vari">
							<h2>Valitse väri</h2>
							<div class="row">
								{#each colors[active_color] as color, i}
									<div class="profile col-6 r7 mb-4">
										<button class="h-100 w-100" name="color" value={color[0]} class:active="{choices.active.color === i}" on:click="{() => choices.active.color = i}" on:click={() => colorClick(color[0])}>
											<div class="row h-100 bg-primary">
												<div class="col-4"><div class="cell" style={"background-color:#"+ color[1]}></div></div>
												<div class="col"><div class="cell">{color[0]}</div></div>
											</div>
										</button>
									</div>
								{/each}
							</div>
						</section>
						{#if state > 3 && dimensions && choices.profile.dimensions}
							<form on:submit|preventDefault={finalSubmit}>
								<section id="mitat">
									<h2 class="mx-0">Mittojen syöttö</h2>
									<div class="mb-4">Pellin minimipituus {choices.profile.dimensions.min} mm,<br>maksimipituus {choices.profile.dimensions.max} mm,<br>leveys {choices.profile.dimensions.width} mm.</div>
									{#if dimensions}
										{#each dimensions as { id, length, amount }, i}
										<div class="rc mb-3">
											<div id={"dr-"+ i} class="row">
												<div class="col">
													<label for={"l"+ id}>Pituus (mm) <sup>*</sup></label>
													<input id={"l"+ id} class="mm pad" type="number" name={"mm"+ id} value={length} min={choices.profile.dimensions.min} max={choices.profile.dimensions.max} required>
												</div>
												<div class="col-4">
													<label for={"la"+ id}>KPL <sup>*</sup></label>
													<input id={"la"+ id} class="pct pad" type="number" name={"amount"+ id} value={amount} step="1" min="1" required>
												</div>
											</div>
										</div>
										{/each}
									{/if}
									<div class="mb-4 rc">
										<button id="newfield" class="big bg-primary" on:click|preventDefault={newFields}>Lisää rivi</button>
										{#if rowNum > 1}<button id="delfield" class="big bg-primary mt" on:click|preventDefault={delFields}>Poista rivi</button>{/if}
									</div>
									<input id="rowNum" type="hidden" value={rowNum}>
								</section>
								<section id="listat">
									<h2>Valitse listat</h2>
									<div class="row">
										{#each lists as { id, title, price, pcs }, i}
											<div class="col-6 r7 mb-4">
												<div class="content bg-white p-4">
													<div class="row">
														<div class="col"><div class="cell tl">{title}<br>{Number(price * multiplier).toFixed(2)} €/{pcs}</div></div>
														<div class="col-4">
															<label class="up" for={'list-'+ id}>{pcs}</label>
															<input id={'list-'+ id} class="pct pad" type="number" name={'list-'+ id} bind:value={lists[i].order} step="1" min="0">
														</div>
													</div>
												</div>
											</div>
										{/each}
									</div>
								</section>
								<section id="extra">
									<h2>Lisävarusteet ja tarvikkeet</h2>
									<div class="row tl">
										<div class="col r13 r72 r4 mb-4">
											<input id="e1" class="inl" name="e1" type=checkbox bind:checked={choices.active.products}>
											<label for="e1" class="inl">Tarvikkeita?</label>
										</div>
										<div class="col r13 r72 r4 mb-4">
											<input id="e2" class="inl" name="e2" type=checkbox bind:checked={choices.active.snow}>
											<label for="e2" class="inl">Lumiesteitä?</label></div>
										<div class="col r13 r72 r4 mb-4">
											<input id="e3" class="inl" name="e3" type=checkbox bind:checked={choices.active.bridges}>
											<label for="e3" class="inl">Kattosiltoja?</label></div>
										<div class="col r13 r72 r4 mb-4">
											<input id="e4" class="inl" name="e4" type=checkbox bind:checked={choices.active.ladders}>
											<label for="e4" class="inl">Tikkaita?</label>
										</div>
										<div class="col r13 r72 r4">
											<input id="e5" class="inl" name="e5" type=checkbox bind:checked={choices.active.rain}>
											<label for="e5" class="inl">Sadevesi-<br>järjestelmät?</label>
										</div>
									</div>
								</section>

								{#if choices.active.products }
									<section id="tarvikkeet">
										<h2>Valitse tarvikkeet</h2>
										<div class="row">
											{#each products as { id, product, price, pcs, amount, extra }, i}
												{#if extra == undefined || extra == choices.profile.snow}
													<div class="col-6 r7 mb-4">
														<div class="content bg-white p-4">
															<div class="row">
																<div class="col"><div class="cell tl"><strong>{product}</strong><br>{#if amount !== undefined}{amount},{/if} {Number(price).toFixed(2)} € / {pcs}</div></div>
																<div class="col-4">
																	<label class="up" for={'product-'+ id}>{pcs}</label>
																	<input id={'product-'+ id} class="pct pad" type="number" name={'product-'+ id} bind:value={products[i].order} step="1" min="0">
																</div>
															</div>
														</div>
													</div>
												{/if}
											{/each}
										</div>
									</section>
								{/if}
								{#if choices.active.snow }
									<section id="lumiesteet">
										<h2>Lumiesteet</h2>
										<div class="row">
											{#each lumieste[choices.profile.snow] as { product, id, price }, i}
												<div class="col-6 r7 mb-4">
													<div class="content bg-white p-4">
														<div class="row">
															<div class="col"><div class="cell tl"><strong>{product}</strong><br>3 m / srj, {Number(price).toFixed(2)} € / kpl</div></div>
															<div class="col-4">
																<label class="up" for={'snow-'+ id}>Sarjat</label>
																<input id={'snow-'+ id} class="pct pad" type="number" name={'snow-'+ id} bind:value={lumieste[choices.profile.snow][i].order} step="1" min="0">
															</div>
														</div>
													</div>
												</div>
											{/each}
										</div>
									</section>
								{/if}
								{#if choices.active.bridges }
									<section id="kattosillat">
										<h2>Kattosillat</h2>
										<div class="row">
											{#each bridges[choices.profile.snow] as { product, id, price, pcs }, i}
												<div class="col-6 r7 mb-4">
													<div class="content bg-white p-4">
														<div class="row">
															<div class="col"><div class="cell tl"><strong>{product}</strong><br>{Number(price).toFixed(2)} € / {pcs}</div></div>
															<div class="col-4">
																<label class="up" for={'bridge-'+ id}>Sarjat</label>
																<input id={'bridge-'+ id} class="pct pad" type="number" name={'bridge-'+ id} bind:value={bridges[choices.profile.snow][i].order} step="1" min="0">
															</div>
														</div>
													</div>
												</div>
											{/each}
										</div>
									</section>
								{/if}
								{#if choices.active.ladders }
									<section id="tikkaat">
										<h2>Tikkaat</h2>
										<div class="row">
											{#each ladders as { product, id, price, pcs }, i}
												<div class="col-6 r7 mb-4">
													<div class="content bg-white p-4">
														<div class="row">
															<div class="col"><div class="cell tl"><strong>{product}</strong><br>{Number(price).toFixed(2)} € / {pcs}</div></div>
															<div class="col-4">
																<label class="up" for={'ladder-'+ id}>{pcs}</label>
																<input id={'ladder-'+ id} class="pct pad" type="number" name={'ladder-'+ id} bind:value={ladders[i].order} step="1" min="0">
															</div>
														</div>
													</div>
												</div>
											{/each}
										</div>
									</section>
								{/if}
								{#if choices.active.rain }
									<section id="sadevesi">
										<h2>Sadevesijärjestelmät</h2>
										<div class="row">
											{#each rainwater as { product, id, price, pcs }, i}
												<div class="col-6 r7 mb-4">
													<div class="content bg-white p-4">
														<div class="row">
															<div class="col"><div class="cell tl"><strong>{product}</strong><br>{Number(price).toFixed(2)} € / {pcs}</div></div>
															<div class="col-4">
																<label class="up" for={'rain-'+ id}>{pcs}</label>
																<input id={'rain-'+ id} class="pct pad" type="number" name={'rain-'+ id} bind:value={rainwater[i].order} step="1" min="0">
															</div>
														</div>
													</div>
												</div>
											{/each}
										</div>
									</section>
								{/if}
								{#if error}<div id="error" class="mb-4">{error}</div>{/if}
								<div class="mt-4"><button class="big bg-primary mb-3">Laske tarjous</button></div>
							</form>
						{/if}
					{/if}
				{/if}
			{:else if state == 5}
				<div class="row">
					<div class="col-6 r7 mb-4">
						<section class="mb-4">
							<div class="content">
								<h2>Valinnat</h2>
								<div class="mb-4"><h3>{choices.profile.title}, {choices.profile.coating.thickness} {choices.profile.coating.coating}, {choices.profile.color}</h3></div>
								<div class="mb-4">
									<table class="table_products" rules="rows"><thead>
										<tr><th class="tl">Pelti</th><th class="tc">Määrä</th><th class="tr">Hinta</th></tr>
									</thead><tbody>
										{#each choices.dimensions as { length, amount, price, square, total, avail }, i}
											<tr><td class="tl table_product">{square} m<sup>2</sup></td><td class="tc amount">{amount}</td><td class="tr table_price">{Number(price).toFixed(2)}</td></tr>
										{/each}
									</tbody></table>
								</div>
								{#if choices.extra[0]}
									<div class="mb-4">
										<table class="table_products" rules="rows"><thead>
											<tr><th class="tl">Tuote</th><th class="tc">Määrä</th><th class="tr">Hinta</th></tr>
										</thead><tbody>
										{#each choices.extra as { title, amount, price }, i}
											<tr><td class="tl table_product">{title}</td><td class="tc amount">x{amount}</td><td class="tr table_price">{Number(price).toFixed(2)}</td></tr>
										{/each}
									</tbody></table></div>
								{/if}
							</div>
						</section>
						<button id="changeit" class="big bg-primary mb-4" on:click={() => state = 4}>Muuta valintoja</button>
						<section>
							<div class="content">
								<h2>Tiivistelmä</h2>
								<table class="table_products"><tbody>
									<tr><td class="label tl"><strong>Tuotteet yht.</strong></td><td class="label tr">{choices.total.products}</td></tr>
									<tr><td class="label tl"><strong>Kuljetus</strong></td><td class="label tr">{choices.total.delivery}</td></tr>
									<tr><td class="label tl"><strong>ALV 24%</strong></td><td class="label tr">{choices.total.vat}</td></tr>
									<tr class="borders"><td class="label tl"><strong>Tilaus yht.</strong></td><td class="label tr">{choices.total.order}</td></tr>
								</tbody></table>
							</div>
						</section>
					</div>
					<form class="col-6 r7 mb-4 tc" on:submit|preventDefault={sendSubmit}>
						<section class="mb-4">
							<div class="content">
								<h2>Yhteystiedot</h2>
								<div class="mb-3"><label for="post_name">Nimi</label><input id="post_name" class="mm pad" type="text" name="post_name" minlength="4" maxlength="50" required></div>
								<div class="mb-3"><label for="post_email">Sähköposti</label><input id="post_email" class="mm pad" type="email" name="post_email" minlength="6" maxlength="60" required></div>
								<div><label for="post_phone">Puhelin</label><input id="post_phone" class="mm pad" type="text" name="post_phone"  minlength="5" maxlength="15" required></div>
							</div>
						</section>
						<button class="big bg-primary" type="submit" name="post_profile" value={json}>Lähetä tarjouspyyntö</button>
					</form>
				</div>
			{:else}
				<div>Kiitos.</div>
			{/if}
			<div id="back"><a class="bg-primary" href="https://www.kestopelti.fi/etusivu/">Palaa Kestopellin etusivulle</a></div>
		</div>
	</div>
</main>